namespace app.pages {
    "use strict";

    export interface IPageConstants {
        Homepage: number;
        Legal: number;
        Finance: number;
        Procedures: number;
        Documents: number;
        Glossary: number;
        AuditInspectionFeedback: number;
        Links: number;
        Teleconferences: number;
        LegalTermsConditions: number;
    }

    angular
        .module("app.pages")
        .constant("pageConstants", <IPageConstants>{
            Homepage: 3,
            Legal: 5,
            Finance: 10,
            Procedures: 16,
            Documents: 12,
            Glossary: 6,
            AuditInspectionFeedback: 13,
            Links: 8,
            Teleconferences: 4,
            LegalTermsConditions: 2
        });
}
