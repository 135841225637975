namespace app.documents {
    "use strict";

    export interface IDocumentCategoryConstants {
        financeDocuments: number;
        homepageDocuments: number;
        procedures: number;
        legalDocuments: number;
        aboutUsDocuments: number;
    }

    angular
        .module("app.documents")
        .constant("documentCategoryConstants", <IDocumentCategoryConstants>{
            financeDocuments: 61,
            homepageDocuments: 6,
            procedures: 2192,
            legalDocuments: 6433,
        });
}
