namespace app.workingGroups.services {
	"use strict";

	export interface IWorkingGroupService {
        readRecord(recordKey: number): ng.IPromise<interfaces.IWorkingGroup>;
        readByType(workingGroupType: number): ng.IPromise<interfaces.IWorkingGroup[]>;
        readMyGroups(): ng.IPromise<interfaces.IWorkingGroup[]>;
        readSubGroups(workingGroupKey: number): ng.IPromise<interfaces.IWorkingGroup[]>;
        readWorkingGroupMembers(workingGroupKey: number): ng.IPromise<subscribers.interfaces.ISubscriber[]>;
        permissionToViewFullWorkingGroup(workingGroupKey: number): ng.IPromise<boolean>;
        readPublicDocuments(recordKey: number, searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument>): ng.IPromise<core.interfaces.ISearchResults<documents.interfaces.IDocument>>;
        readPrivateDocuments(recordKey: number, searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument>): ng.IPromise<core.interfaces.ISearchResults<documents.interfaces.IDocument>>;
	}

	class WorkingGroupService extends app.core.services.BaseService implements IWorkingGroupService {
		private serviceBase: string = "/api/workinggroup/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		readPublicDocuments(recordKey: number, searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument>): ng.IPromise<core.interfaces.ISearchResults<documents.interfaces.IDocument>> {
            return this.$http
                .put(this.serviceBase + "readpublicdocuments/" + recordKey, searchParameters)
                .then((response: ng.IHttpPromiseCallbackArg<core.interfaces.ISearchResults<documents.interfaces.IDocument>>): core.interfaces.ISearchResults<documents.interfaces.IDocument> => response.data)
                .catch((error) => this.logError(error));
        };

		readPrivateDocuments(recordKey: number, searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument>): ng.IPromise<core.interfaces.ISearchResults<documents.interfaces.IDocument>> {
            return this.$http
                .put(this.serviceBase + "readprivatedocuments/" + recordKey, searchParameters)
                .then((response: ng.IHttpPromiseCallbackArg<core.interfaces.ISearchResults<documents.interfaces.IDocument>>): core.interfaces.ISearchResults<documents.interfaces.IDocument> => response.data)
                .catch((error) => this.logError(error));
        };

		readRecord(recordKey: number): ng.IPromise<interfaces.IWorkingGroup> {
            return this.$http
                .get(this.serviceBase + "read/" + recordKey)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IWorkingGroup>): interfaces.IWorkingGroup => response.data)
                .catch((error) => this.logError(error));
		};

        readByType(workingGroupType: number): ng.IPromise<interfaces.IWorkingGroup[]> {
            return this.$http
                .get(this.serviceBase + "readbytype/" + workingGroupType)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IWorkingGroup[]>): interfaces.IWorkingGroup[] => response.data)
                .catch((error) => this.logError(error));
        };

        readMyGroups(): ng.IPromise<interfaces.IWorkingGroup[]> {
            return this.$http
                .get(this.serviceBase + "readmygroups")
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IWorkingGroup[]>): interfaces.IWorkingGroup[] => response.data)
                .catch((error) => this.logError(error));
        };

        readSubGroups(workingGroupKey: number): ng.IPromise<interfaces.IWorkingGroup[]> {
            return this.$http
                .get(this.serviceBase + "readsubgroups/" + workingGroupKey)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IWorkingGroup[]>): interfaces.IWorkingGroup[] => response.data)
                .catch((error) => this.logError(error));
        };

        readWorkingGroupMembers(workingGroupKey: number): ng.IPromise<subscribers.interfaces.ISubscriber[]> {
            return this.$http
                .get(this.serviceBase + "readworkinggroupmembers/" + workingGroupKey)
                .then((response: ng.IHttpPromiseCallbackArg<subscribers.interfaces.ISubscriber[]>): subscribers.interfaces.ISubscriber[] => response.data)
                .catch((error) => this.logError(error));
        };

        permissionToViewFullWorkingGroup(workingGroupKey: number): ng.IPromise<boolean> {
            return this.$http
                .get(this.serviceBase + "permissiontoviewfullworkinggroup/" + workingGroupKey)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => response.data)
                .catch((error) => this.logError(error));
        };
    }

	angular
		.module("app.workingGroups.services")
		.service("workingGroupService", WorkingGroupService);
}