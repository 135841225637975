namespace app.workingGroups {
    "use strict";

    interface ICwnGroupMenuVm {
        workingGroupKey: number;
        permissionToViewFullWorkingGroup: boolean;
    }

	class CwnGroupMenuController implements ICwnGroupMenuVm {
        workingGroupKey: number;
        permissionToViewFullWorkingGroup: boolean = false;
        bindData: boolean = false;

		static $inject: string[] = ["$scope", "workingGroupService"];
        constructor(
            private $scope: ng.IScope,
            private workingGroupService: workingGroups.services.IWorkingGroupService) {

            this.$scope.$watch(
                () => this.bindData,
                (formBind) => {
                    if (formBind == true) {
                        this.activate();
                    }
                });

        }

        activate() {
            this.workingGroupService
                .permissionToViewFullWorkingGroup(this.workingGroupKey)
                .then((permissionToViewFullWorkingGroup: boolean) => {
                    this.permissionToViewFullWorkingGroup = permissionToViewFullWorkingGroup;
                });
        }
    }

    interface IDirectiveScope {
        workingGroupKey: string;
        bindData: string;
    }

    class CwnGroupMenu implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnGroupMenu;
        }

        scope: IDirectiveScope = {
            bindData: "=",
            workingGroupKey: "="
        };
        restrict: string = "E";
        templateUrl: string = "/app/workingGroups/widgets/cwnGroupMenu/cwnGroupMenu.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;
		controller = CwnGroupMenuController;
    }

    angular
        .module("app.workingGroups")
        .directive("cwnGroupMenu", CwnGroupMenu.instance);
}