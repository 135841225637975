namespace app.subscribers.services {
	"use strict";

	export interface ISubscriberService {
        createRecord(record: interfaces.ISubscriber): ng.IPromise<interfaces.ISubscriber>;
        membersDirectory(): ng.IPromise<interfaces.ISubscriber>;
	}

	class SubscriberService extends app.core.services.BaseService implements ISubscriberService {
		private serviceBase: string = "/api/subscriber/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		createRecord(record: interfaces.ISubscriber): ng.IPromise<interfaces.ISubscriber> {
			return this.$http
				.post(this.serviceBase + "create", record)
				.then((response: ng.IHttpPromiseCallbackArg<interfaces.ISubscriber>): interfaces.ISubscriber => response.data)
				.catch((error) => this.logError(error));
		};

		membersDirectory(): ng.IPromise<interfaces.ISubscriber> {
			return this.$http
				.get(this.serviceBase + "membersdirectory")
				.then((response: ng.IHttpPromiseCallbackArg<interfaces.ISubscriber[]>): interfaces.ISubscriber[] => response.data)
				.catch((error) => this.logError(error));
		};
	}

	angular
		.module("app.subscribers.services")
		.service("subscriberService", SubscriberService);
}