namespace app.widgets {
	"use strict";

    interface ICwnDocumentVm {
	}

	class CwnDocumentController implements ICwnDocumentVm {
        static $inject: string[] = [];
        constructor() {
		}
	}

    interface IDirectiveScope {
        document: string;
    }

	class CwnDocument implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnDocument;
		}

        scope: IDirectiveScope = {
            document: "="
        };

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnDocument/cwnDocument.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;

        controller = CwnDocumentController;
    }

	angular
		.module("app.widgets")
		.directive("cwnDocument", CwnDocument.instance);
}