namespace app.documents.services {
	"use strict";

    export interface IDocumentService {
        readByDocumentCategoryKey(documentCategoryKey: number, searchParameters: core.interfaces.ISearchParameters<interfaces.IDocument>): ng.IPromise<core.interfaces.ISearchResults<interfaces.IDocument>>;
        readByDocumentTypeKey(documentTypeKey: number, searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument>): ng.IPromise<core.interfaces.ISearchResults<documents.interfaces.IDocument>>;
        searchRecords(searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument>): ng.IPromise<core.interfaces.ISearchResults<documents.interfaces.IDocument>>;
    }

	class DocumentService extends app.core.services.BaseService implements IDocumentService {
		private serviceBase: string = "/api/document/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		searchRecords(searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument>): ng.IPromise<core.interfaces.ISearchResults<documents.interfaces.IDocument>> {
            return this.$http
                .post(this.serviceBase + "search", searchParameters)
                .then((response: ng.IHttpPromiseCallbackArg<core.interfaces.ISearchResults<documents.interfaces.IDocument>>): core.interfaces.ISearchResults<documents.interfaces.IDocument> => response.data)
                .catch((error) => this.logError(error));
        };


		readByDocumentCategoryKey(documentCategoryKey: number, searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument>): ng.IPromise<core.interfaces.ISearchResults<documents.interfaces.IDocument>> {
            return this.$http
                .put(this.serviceBase + "readbydocumentcategorykey/" + documentCategoryKey, searchParameters)
                .then((response: ng.IHttpPromiseCallbackArg<core.interfaces.ISearchResults<documents.interfaces.IDocument>>): core.interfaces.ISearchResults<documents.interfaces.IDocument> => response.data)
                .catch((error) => this.logError(error));
        };

		readByDocumentTypeKey(documentTypeKey: number, searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument>): ng.IPromise<core.interfaces.ISearchResults<documents.interfaces.IDocument>> {
            return this.$http
                .put(this.serviceBase + "readbydocumenttypekey/" + documentTypeKey, searchParameters)
                .then((response: ng.IHttpPromiseCallbackArg<core.interfaces.ISearchResults<documents.interfaces.IDocument>>): core.interfaces.ISearchResults<documents.interfaces.IDocument> => response.data)
                .catch((error) => this.logError(error));
        };
    }

	angular
		.module("app.documents.services")
		.service("documentService", DocumentService);
}