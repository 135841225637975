namespace app {
	"use strict";

	angular
        .module("app", [
            "app.core",
            "app.documents",
            "app.meetings",
            "app.pages",
            "app.publicPages",
            "app.subscribers",
            "app.widgets",
            "app.workingGroups"
		]);
}