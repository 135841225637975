namespace app.meetings {
    "use strict";

    interface IMeetingResponseVm {
        formLoading: boolean;
        formSaving: boolean;
        meetings: interfaces.IMeetingListReturnParameters[];
        questions: interfaces.IQuestion[];
        yesNoQuestions: core.interfaces.IStringStringValue[];
        attendanceStatus: core.interfaces.IKeyStringValue[];
        meetingQuestionResponses: interfaces.IMeetingQuestionResponse[];
        meetingSubscriberLink: interfaces.IMeetingSubscriberLink;
        savedAttendanceStatus: number;
        saveClick(invalidForm: boolean): void;
		alert: kendo.ui.Notification;
		alertConfiguration: kendo.ui.NotificationOptions;
    }

    class MeetingResponseController implements IMeetingResponseVm {
		alert: kendo.ui.Notification;
		alertConfiguration: kendo.ui.NotificationOptions = this.kendoNotification.configure();

        formLoading: boolean = true;
        formSaving: boolean = false;
        meeting: interfaces.IMeeting = {
			id: parseInt(this.$routeParams.id)
        };
        meetings: interfaces.IMeetingListReturnParameters[];
        questions: interfaces.IQuestion[];
        yesNoQuestions: core.interfaces.IStringStringValue[];
        attendanceStatus: core.interfaces.IKeyStringValue[];
        meetingQuestionResponses: interfaces.IMeetingQuestionResponse[];
        meetingSubscriberLink: interfaces.IMeetingSubscriberLink;
        savedAttendanceStatus: number;

        static $inject: string[] = ["$location", "$q", "$routeParams", "meetingAttendanceStatusConstants", "meetingAttendanceStatusService", "meetingQuestionResponseService", "meetingService", "meetingSubscriberLinkService", "meetingYesNoQuestionResponseService", "kendoNotification", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private $routeParams: app.core.interfaces.IEditRouteParams,
            private meetingAttendanceStatusConstants: IMeetingAttendanceStatusConstants,
            private meetingAttendanceStatusService: services.IMeetingAttendanceStatusService,
            private meetingQuestionResponseService: services.IMeetingQuestionResponseService,
            private meetingService: services.IMeetingService,
            private meetingSubscriberLinkService: services.IMeetingSubscriberLinkService,
            private meetingYesNoQuestionResponseService: services.IMeetingYesNoQuestionResponseService,
			private kendoNotification: blocks.kendoConfiguration.IKendoNotification,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;

            let promises: ng.IPromise<any>[] = [
                this.meetingService.readMeetingDetails(this.meeting.id),
                this.meetingYesNoQuestionResponseService.readAllRecords(),
                this.meetingAttendanceStatusService.readAllRecords(this.meeting.id),
                this.meetingService.readMeetingQuestions(this.meeting.id),
                this.meetingQuestionResponseService.readByMeetingKey(this.meeting.id),
                this.meetingSubscriberLinkService.readByMeetingKey(this.meeting.id),
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(
            meetings: interfaces.IMeetingListReturnParameters[],
            yesNoQuestions: core.interfaces.IStringStringValue[],
            attendanceStatus: core.interfaces.IKeyStringValue[],
            questions: interfaces.IQuestion[],
            meetingQuestionResponses: interfaces.IMeetingQuestionResponse[],
            meetingSubscriberLink: interfaces.IMeetingSubscriberLink) {

            this.meetings = meetings;
            this.questions = questions;
            this.yesNoQuestions = yesNoQuestions;
            this.attendanceStatus = attendanceStatus;
            this.meetingQuestionResponses = meetingQuestionResponses;

            // The message on the screen changes depending on what the saved value is.  If we don't keep a record of the saved value the message changes after the question
            // "Will you or someone else from your company be attending this meeting" is answered.
            this.savedAttendanceStatus = meetingSubscriberLink.attending;

            // If the no reply value is set the question "Will you or someone else from your cmopany be attending this meeting" isn't mandatory.
            if (meetingSubscriberLink.attending === this.meetingAttendanceStatusConstants.NoReply) {
                meetingSubscriberLink.attending = null;
            }

            this.meetingSubscriberLink = meetingSubscriberLink;
            this.meetingSubscriberLink.replyDate = moment(this.meetingSubscriberLink.replyDate, "DD/MM/YYYY").format("dddd MMMM D YYYY");
            this.questions.forEach(this.iterateQuestionsArray);


            this.formLoading = false;
        }

        iterateQuestionsArray = (question) => {
            question.response = this.readQuestionResponse(question.id);
        }

        readQuestionResponse(questionKey): any {
            for (var i = 0; i < this.meetingQuestionResponses.length; i++) {
                if (this.meetingQuestionResponses[i].questionid === questionKey) {
                    return this.meetingQuestionResponses[i];
                }
            }

            return {
                id: 0,
                response: "",
                meetingid: this.meeting.id,
                questionid: questionKey
            };
        }

		saveClick(invalidForm: boolean): void {
			if (invalidForm === false) {
				this.saveRecord();
			}
        }

        saveRecord(): ng.IPromise<void> {
            this.formSaving = true;

            let promises: ng.IPromise<any>[] = [
                this.meetingSubscriberLinkService.updateRecord(this.meetingSubscriberLink.id, this.meetingSubscriberLink),
                this.meetingQuestionResponseService.saveRecords(this.formatMeetingQuestionResponse())
            ];

            return this.$q
                .all(promises)
                .then(() => {
                    this.formSaving = false;
                    this.displaySaveMessage();
                    this.widgetRefresh.cwnNewMeetingInvitationCount = true;
                    this.$location.path("/meetings");
                });
        }

        formatMeetingQuestionResponse(): interfaces.ISaveMeetingQuestionResponsesPostData {
            return <interfaces.ISaveMeetingQuestionResponsesPostData> {
                meetingid: this.meeting.id,
                attending: this.meetingSubscriberLink.attending,
                meetingQuestionResponses: this.combineMeetingQuestionResponses()
            };
        }

        combineMeetingQuestionResponses() {
            var responses = [];

            this.questions.forEach((question) => {
                responses.push(question.response);
            });

            return responses;
        }

        displaySaveMessage() {
        //    this.alert.show({ message: "Record saved" }, "save");
        }
    }

    angular
        .module("app.meetings")
        .controller("MeetingResponseController", MeetingResponseController);

}